// export default ChatRating;

import { useState } from "react";
import { ThumbsUp, ThumbsDown } from "lucide-react";
import { Button } from "antd";
import FeedbackModal from "./FeedbackModal"; // Import the modal component
import "../../src/styles/components/chatrating.scss"; // Import SCSS file

const ChatRating = ({ onRate }) => {
  const [rating, setRating] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [message, setMessage] = useState(""); // Store feedback message

  const handleRating = (value) => {
    setRating(value);

    if (onRate) onRate(value);

    if (value === "up") {
      setMessage("Thanks for your feedback!");
      setTimeout(() => setMessage(""), 3000); // Hide message after 3 seconds
    } else {
      setMessage(""); // Ensure no message is shown when thumbs-down is clicked
      setTimeout(() => setIsModalVisible(true), 500); // Show modal after short delay
    }
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleFeedbackSubmit = (feedbackData) => {
    console.log("User Feedback:", feedbackData);
    // Send feedback data to backend (if needed)
    handleCloseModal();
  };

  return (
    <div className="chat-rating">
      <Button
        onClick={() => handleRating("up")}
        className={rating === "up" ? "active-up" : ""}
        type="text"
      >
        <ThumbsUp size={20} />
      </Button>

      <Button
        // onClick={() => handleRating("down")}
        onClick={() => handleRating("down")}
        className={rating === "down" ? "active-down" : ""}
        type="text"
        danger
      >
        <ThumbsDown size={20} />
      </Button>

      {/* Feedback message */}
      {message && <span className="feedback-message">{message}</span>}

      {/* Feedback Modal */}
      <FeedbackModal
        visible={isModalVisible}
        onClose={handleCloseModal}
        onSubmit={handleFeedbackSubmit}
      />
    </div>
  );
};

export default ChatRating;
