import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

function GoogleSignInButton() {
    const navigate = useNavigate();

    useEffect(() => {
        // Load the Google Identity Services script dynamically
        const script = document.createElement('script');
        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        // Initialize Google Identity Services after the script loads
        script.onload = () => {
            window.google.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENTID,
                callback: handleCredentialResponse
            });
            window.google.accounts.id.renderButton(
                document.getElementById('signInDiv'),
                { theme: "outline", size: "large" }
            );
        };

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    function handleCredentialResponse(response) {
        console.log("Encoded JWT ID token: " + response.credential);
        console.log(response);
        const idToken = response.credential;
        fetch(`${process.env.REACT_APP_API_KEY}/CustomerGoogleSignup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ idToken }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log("data:", data);
                if (data.success) {
                    console.log("User authenticated successfully:", data.token);
                    localStorage.setItem("authToken", data.token);
                    toast.success("Login Successfully", {
                        toastId: "loginsuccess",
                    });
                    navigate("/connect-to-hubspot");
                } else {
                    console.log("Authentication failed");
                }
            })
            .catch((err) => console.error("Error:", err));
    }

    return (
        <div>
            <div id="signInDiv"></div>
        </div>
    );
}

export default GoogleSignInButton;