// import React, { useState } from "react";
// import { AiOutlineClose } from "react-icons/ai"; // Import close icon
// import chatBot from "../assets/icons/chotBotIcon.svg";
// import msgSendIcon from "../assets/icons/msgSendIcon.svg";

// const Chatbot = () => {
//   const [showPopup, setShowPopup] = useState(false);

//   const handletoggle = () => {
//     setShowPopup(!showPopup);
//   };
//   const userInitials = localStorage.getItem("name")


//   return (
//     <div>
//       <div className="chatbot-wrap">
//         <div className="chatbot-meassge-box">
//           <img
//             className="img-fluid cursor-pointer"
//             src={chatBot}
//             alt=""
//             onClick={handletoggle}
//           />
//           {showPopup && (
//             <div className="chatDiv">
//               <div className="header">
//                 <p className="text-large fw-medium text-white text-left">
//                   Hi {userInitials}!
//                 </p>
//                 <p className="text-large fw-medium text-white text-left">
//                   How can we help you?
//                 </p>
//                 {/* React icon for close */}
//                 <AiOutlineClose
//                   className="close-icon"
//                   onClick={handletoggle}
//                 />
//               </div>

//               <div className="innerContentWrapper d-flex justify-content-between flex-column">
//                 <div className="parentChatBox">
//                   {/* <div className="senderMsg">
//                     <p className="fw-medium">
//                       How do I ensure my organization adheres to anti-bribery
//                       laws in the US
//                     </p>
//                   </div> */}
//                   {/* <div className="receiverMsg">
//                     <p className="fw-medium">
//                       To ensure your organization adheres to anti-bribery laws
//                       in the US, you should [list key actions]. I can also
//                       provide a detailed checklist if needed.
//                     </p>
//                   </div> */}
//                 </div>
//                 <div className="msgTextField w-100">
//                   <input
//                     className="w-100 pr-5"
//                     type="text"
//                     placeholder="Message..."
//                   />
//                   <img
//                     className="msgSendIcon cursor-pointer"
//                     src={msgSendIcon}
//                     alt=""
//                   />
//                 </div>
//               </div>
//               <div className="mainArea"></div>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Chatbot;

import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai"; // Import close icon
import chatBot from "../assets/icons/chotBotIcon.svg";
import msgSendIcon from "../assets/icons/msgSendIcon.svg";

const Chatbot = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handletoggle = () => {
    setShowPopup(!showPopup);
  };
  const userInitials = localStorage.getItem("name")


  return (
    <div>
      <div className="chatbot-wrap">
        <div className="chatbot-meassge-box">
          <img
            className="img-fluid cursor-pointer"
            src={chatBot}
            alt=""
            onClick={handletoggle}
          />
          {showPopup && (
            <div className="chatDiv">
              <div className="header">
                <p className="text-large fw-medium text-white text-left">
                  Hi {userInitials}!
                </p>
                <p className="text-large fw-medium text-white text-left">
                  How can we help you?
                </p>
                {/* React icon for close */}
                <AiOutlineClose
                  className="close-icon"
                  onClick={handletoggle}
                />
              </div>

              <div className="innerContentWrapper d-flex justify-content-between flex-column">
                <div className="parentChatBox">
                  {/* <div className="senderMsg">
                    <p className="fw-medium">
                      How do I ensure my organization adheres to anti-bribery
                      laws in the US
                    </p>
                  </div> */}
                  {/* <div className="receiverMsg">
                    <p className="fw-medium">
                      To ensure your organization adheres to anti-bribery laws
                      in the US, you should [list key actions]. I can also
                      provide a detailed checklist if needed.
                    </p>
                  </div> */}
                </div>
                <div className="msgTextField w-100">
                  <input
                    className="w-100 pr-5"
                    type="text"
                    placeholder="Message..."
                  />
                  <img
                    className="msgSendIcon cursor-pointer"
                    src={msgSendIcon}
                    alt=""
                  />
                </div>
              </div>
              <div className="mainArea"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
