import React, { useEffect, useState } from "react";
import Buttoon from "../components/buttoon";
import pageIcon from "../assets/icons/mm.svg";
import axios from "axios";
import { Link } from "react-router-dom";
import mammoth from "mammoth";
import { useLocation } from "react-router-dom";

const AmlPolicy = () => {
  const [convertedFiles, setConvertedFiles] = useState({});
  const [documentText, setDocumentText] = useState();
  const location = useLocation();
  const { savedDocument, chatId, chatName } = location.state || {};

  const convertDocxToText = async (savedDocument) => {
    try {
      if (
        !savedDocument ||
        typeof savedDocument !== "string" ||
        !savedDocument.startsWith("http")
      ) {
        console.error("Invalid savedDocument URL:", savedDocument);
        return;
      }

      const response = await axios.get(savedDocument, {
        responseType: "blob",
      });
      const arrayBuffer = await response.data.arrayBuffer();

      const result = await mammoth.extractRawText({ arrayBuffer });
      setDocumentText(result.value);
    } catch (error) {
      console.error("Error converting .docx file to text:", error);
    }
  };

  useEffect(() => {
    const handleFileConversion = async () => {
      if (savedDocument && chatId) {
        await convertDocxToText(savedDocument);
        setConvertedFiles((prev) => ({ ...prev, [chatId]: documentText }));
      }
    };
    handleFileConversion();
  }, [savedDocument, chatId, documentText]);

  const parseMarkdown = (text) => {
    if (typeof text !== "string") return "";

    text = text.replace(
      /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
      '<a href="$2" target="_blank" style="color: blue; text-decoration: underline;">$1</a>'
    );

    text = text.replace(/^# (.+)\s*$/gm, '<h1 style="font-size: 24px; color: black;">$1</h1>');
    text = text.replace(/^## (.+)\s*$/gm, '<h2 style="font-size: 20px; color: black; font-weight: bold;">$1</h2>');
    text = text.replace(/^### (.+)\s*$/gm, '<h3 style="font-size: 18px; color: black;">$1</h3>');
    text = text.replace(/^#### (.+)\s*$/gm, '<h4 style="font-size: 16px; color: black;">$1</h4>');
    text = text.replace(/^##### (.+)\s*$/gm, '<h5 style="font-size: 14px; color: black;">$1</h5>');

    text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    text = text.replace(/\**(.*?)\*/g, "<em>$1</em>");
    text = text.replace(/- \*\*(.+?):\*\* (.+)/g, "<li><strong>$1:</strong> $2</li>");
    text = text.replace(/\*\*(.+?):\*\* (.+)/g, "<span style='font-weight: bold;'>$1:</span> $2");
    text = text.replace(/\*\*(.+?):\*\* (.+)/g, "<div style='margin-bottom: 5px;'><span style='font-weight: bold;'>$1:</span> $2</div>");

    // Convert Horizontal Lines (---)
    text = text.replace(/^---\s*$/gm, '<div style="border-top: 1px solid #ccc; width: 100%; margin: 10px 0;"></div>');
    text = text.replace(/^--\s*$/gm, '<div style="border-top: 1px solid #ccc; width: 100%; margin: 10px 0;"></div>');

    // Convert Markdown Tables
    const tableRegex = /\|(.+)\|\n\|(:?-+:?\|)+\n((\|.+?\|\n)+)/g;
    text = text.replace(tableRegex, (match) => {
      const rows = match.trim().split("\n");
      const headers = rows[0].split("|").filter((cell) => cell.trim());
      const body = rows.slice(2).map((row) => row.split("|").filter((cell) => cell.trim()));

      let tableHtml = '<table style="border: 1px solid #ddd; border-collapse: collapse; width: 100%; margin: 10px 0;">';

      // Table Header
      tableHtml += "<thead><tr>";
      headers.forEach((header) => {
        tableHtml += `<th style="border: 1px solid #ddd; padding: 8px; text-align: left;">${header.trim()}</th>`;
      });
      tableHtml += "</tr></thead>";

      // Table Body
      tableHtml += "<tbody>";
      body.forEach((row) => {
        tableHtml += "<tr>";
        row.forEach((cell) => {
          tableHtml += `<td style="border: 1px solid #ddd; padding: 8px;">${cell.trim()}</td>`;
        });
        tableHtml += "</tr>";
      });
      tableHtml += "</tbody></table>";

      return tableHtml;
    });

    return text;
  };

  return (
    <>
      <div className="chat pe-4">
        <div>
          <div className="kycWriting d-flex justify-content-between align-items-center fixed">
            <h3>{chatName}</h3>
            <Buttoon
              className="btn btn-primary"
              minWidth={208}
              minHeight={39}
              text="Save Document"
            />
          </div>
          <hr />
        </div>
        <div className="chat-data d-flex flex-column justify-content-between mt-5">
          <div className="chattname">
            <img className="pageIcon" src={pageIcon} alt="PageIcon" />
            <div className="chatting">
              <h4>ChatKYC</h4>
              <h5>Document Completeness:</h5>
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: parseMarkdown(documentText), // Ensure the documentText is passed here and parsed
                }}
              />
              <div className="d-flex gap-2 mt-4">
                <Link
                  style={{
                    backgroundColor: "#0085ff",
                    width: "auto",
                    padding: "10px",
                    color: "white",
                    borderRadius: "10px",
                    fontWeight: "600",
                  }}
                  to={`/dashboard/${chatId}`}
                >
                  More Chat?
                </Link>
              </div>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </>
  );
};

export default AmlPolicy;
