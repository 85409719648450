import React, { useState } from "react";
import Buttoon from "../../components/buttoon";
import axios from "axios";
import Swal from "sweetalert2";

const UserFeedback = () => {
  const [feedback, setFeedback] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission state

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hasValidContent = /[A-Za-z0-9]/.test(feedback);

    if (!feedback.trim() || !hasValidContent || feedback.trim().length < 5) {
      setError(
        "Feedback is required, cannot be empty, and must be at least 5 characters."
      );
      return;
    }

    setIsSubmitting(true); 

    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/createfeedback`,
        { feedback },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        title: "Feedback Submitted",
        text: "Your feedback has been submitted successfully.",
      });

      setFeedback(""); 
      setError(""); 
    } catch (error) {
      Swal.fire({
        title: "Error",
        text:
          error.response?.data?.message ||
          "An error occurred while submitting your feedback.",
      });
    } finally {
      setIsSubmitting(false); // Re-enable button after response
    }
  };

  return (
    <div className="userFeedbackContainer">
      <p className="feedbackText ps-3 fw-semibold">Give Your Feedback</p>
      <p className="ps-3 pb-3 fw-semibold">
        Please leave any thoughts, comments or suggestions{" "}
        <span className="text-danger">*</span>
      </p>
      <textarea
        className="textArea"
        value={feedback}
        onChange={handleFeedbackChange}
      />
      {error && <p className="error-message mt-1" style={{ color: "red" }}>{error}</p>}
      <div className="userfeedback-submitbutton">
        <Buttoon
          className={`btn btn-primary mt-4 m-auto d-flex h-center v-center ${
            isSubmitting ? "disabled" : ""
          }`}
          minWidth={183}
          minHeight={43}
          text={isSubmitting ? "Submitting..." : "Submit"} // Change text while submitting
          onClick={isSubmitting ? null : handleSubmit} // Prevent multiple clicks
          disabled={isSubmitting} // Disable button
        />
      </div>
    </div>
  );
};

export default UserFeedback;
