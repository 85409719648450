import React, { useState } from "react";
import leftPic from "../assets/images/accounts/loginPic.png";
import Input from "../shared/input";
import Buttoon from "../components/buttoon";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const ForgetPass = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // Track API call status

  const validate = () => {
    const errors = {};
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitting(true); // Disable button while API is processing
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/forget-password`,
          { email }
        );
        Swal.fire({
          icon: 'success',
          title: 'Verification Email Sent',
          text: 'Please check your email for the verification code.',
        });
        navigate("/verify", { state: { email } });
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.response ? error.response.data.message : 'An error occurred',
        });
      } finally {
        setIsSubmitting(false); // Re-enable button after response
      }
    }
  };

  return (
    <div className="loginWrapper">
      <div className="mainContainer">
        <div className="leftArea">
          <img src={leftPic} alt="" />
        </div>
        <div className="rightArea v-center h-center">
          <div className="midBox">
            <div className="loginTitle v-center h-center flex-column gap-2">
              <h3 className="fw-bold">Forget Password</h3>
              <p className="fw-semibold">Enter your email for OTP verification</p>
            </div>
            <form className="w-100" onSubmit={handleSubmit}>
              <div className="inputFieldContainer v-center h-center flex-column gap-3">
                <div className="emailBox h-center justify-content-start flex-column gap-2">
                  <label className="text-small fw-bold mt-2" htmlFor="email">Email</label>
                  <Input
                    wrapperClass="w-100"
                    inputClass="fw-medium text-black"
                    value={email}
                    height="46px"
                    type="email"
                    className="ftrEmail"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && <p className="error-message mt-1">{errors.email}</p>}
                </div>
              </div>
              <Buttoon
                className={`btn btn-primary mb-1 mt-4 nextBtn ${isSubmitting ? "disabled" : ""}`}
                width={100}
                minWidth={360}
                minHeight={39}
                text={isSubmitting ? "Processing..." : "Next"}
                type="submit"
                disabled={isSubmitting} // Disable button while API is processing
              />
            </form>
            <div className="bottomSection v-center h-center flex-column gap-1">
              <p className="text-small fw-medium mt-0">
                Already have an account? <Link to="/login"><span className="text-primary text-underline">Login!</span></Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPass;
