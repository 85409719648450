// DashboardProvider.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import checkUserPlan from '../middleware/planServices';
import checkUserDocument from '../middleware/planServices';
import { message } from "antd";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const DashboardContext = createContext();

export const useDashboardContext = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }
  return context;
};

export const DashboardProvider = ({ children }) => {
  const [sidebarData, setSidebarData] = useState("Hello world"); // Shared data for Sidebar
  const [chatMessages, setChatMessages] = useState([]);
  const [messageResponse, setMessageResponse] = useState(null);
  const [documentResponse, setDocumentResponse] = useState(null);
  const [userDocument, setUserDocument] = useState([]);
  const [UserData, setUserData] = useState([]);
  const [CurrentNewChatId, setCurrentNewChatId] = useState(null);
  const [isCreatingChat, setIsCreatingChat] = useState(false); 
  const [isSavingDocument, setIsSavingDocument] = useState(false); 
  const [disableChat, setDisableChat] = useState(false); 

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserChats = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/chatApi/chat`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUserData(response.data.data);

      } catch (error) {
        console.error("Error fetching user chats:", error);
      }
    };
    fetchUserChats();
  }, [messageResponse]);



  useEffect(() => {
    const fetchUserDocument = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/chatApi/savedDocument`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUserDocument(response.data.data);
      } catch (error) {
        console.error("Error fetching user documents:", error);
      }
    };
    fetchUserDocument();
  }, [documentResponse]);


  const handleSendMessage = async ({ messageContent, files, chatId, userId }) => {
    if (!messageContent && files.length === 0) {
      message.warning("Please enter a message or select a file to upload.");
      return;
    }

    const payload = new FormData();
    files.forEach((file) => payload.append("files", file));
    payload.append("chat_id", chatId);
    payload.append("user_id", userId);
    payload.append("prompt", messageContent);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_PHYTHON}/chat`,
        payload,
        { headers: { "Content-Type": "multipart/form-data" } }
      );

      const botResponse = response.data.response;
      const updatedName = response.data.name;
      setMessageResponse(updatedName)

      setUserData((prevUserData) =>
        prevUserData.map((chat) =>
          chat.chat_id === chatId && chat.name === "New Chat"
            ? { ...chat, name: updatedName } 
            : chat
        )
      );

      return { botResponse };
    } catch (error) {
      message.error("Failed to process the request. Please try again.");
      console.error("Error in handleSendMessage:", error);
      throw error;
    }
  };


  
  const handleSaveDocument = async (chatId, userId) => {
    if (isSavingDocument) return; 
    setIsSavingDocument(true);
    setDisableChat(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL_PHYTHON}/save_document`,
        {
          params: { chat_id: chatId, user_id: userId },
        }
      );
      const botResponse = response?.data?.filename;
      setDocumentResponse(botResponse);
      setDisableChat(false); 
      if (botResponse) {
        return { botResponse };
      } else {
        throw new Error("No filename returned in the response.");
      }
    } catch (error) {
      message.error("Failed to save document. Please try again.");
      console.error("Error in handleSaveDocument:", error);
      throw error;
    } finally {
      setIsSavingDocument(false);
    }
  };

  const handleDeleteDocument = async (chatId) => {
    try {
      const token = localStorage.getItem("token");

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/chatApi/delete_savedDocument`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { chat_id: chatId },
        }
      );

      const updatedUserDocument = userDocument.filter(
        (document) => document.chat_id !== chatId
      );

      setUserDocument(updatedUserDocument);

      localStorage.setItem(
        "document_list",
        JSON.stringify(updatedUserDocument)
      );

      message.success("Document deleted successfully!");
      console.log("Document deleted successfully:", chatId);
    } catch (error) {
      message.error("Failed to delete document. Please try again.");
      console.error("Failed to delete document:", error);
    }
  };

  const handleNewChatClick = async () => {
    if (isCreatingChat) return;
    setIsCreatingChat(true);
    const existingChat = UserData.find(chat => chat.chat_id === CurrentNewChatId);
    console.log(existingChat)

    if (existingChat) {
      const hasNoData = (!existingChat.conversations || existingChat.conversations.length === 0) &&
        (!existingChat.file_count || existingChat.file_count === 0);
      if (hasNoData) {
        message.warning("This chat has no data (messages or files). You cannot create a new chat with this ID.");
        return;
      }
    }

    try {
      await checkUserPlan();

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_PHYTHON}/new_chat`,
        {}
      );

      const newChatId = response.data.chat_id;
      setCurrentNewChatId(newChatId);

      setUserData((prevUserData) => [
        { chat_id: newChatId, name: "New Chat", isNew: true },
        ...prevUserData,
      ]);

      navigate(`/dashboard/${newChatId}`);

    } catch (error) {
      if (error.status && error.status === 403) {
        throw error;
      }
      message.error("Failed to create a new chat. Please try again.");
    } finally {
      setIsCreatingChat(false);
    }
  };


  // const handleNewChatClick = async () => {
  //   try {

  //   await checkUserPlan();
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_BASE_URL_PHYTHON}/new_chat`,
  //       {}
  //     );

  //     const newChatId = response.data.chat_id;
  //     setCurrentNewChatId(newChatId);

  //     const newUserData = [
  //       { chat_id: newChatId, name: "New Chat", isNew: true },
  //       ...UserData,
  //     ];

  //     setUserData(newUserData);
  //     navigate(`/dashboard/${newChatId}`);
  //   } catch (error) {
  //     console.error("Failed to create a new chat:", error);
  //     throw error;
  //   }
  // };



  const handleDeleteChat = async (chatId) => {
    try {
      const token = localStorage.getItem("token");

      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/chatApi/deletechat`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { chat_id: chatId },
        }
      );

      const updatedUserData = UserData.filter(
        (chat) => chat.chat_id !== chatId
      );

      setUserData(updatedUserData);
      localStorage.setItem("chat_list", JSON.stringify(updatedUserData));

      message.success("Chat deleted successfully!");

      if (!updatedUserData.some((chat) => chat.name === "New Chat")) {
        handleNewChatClick();
      }
    } catch (error) {
      message.error("Failed to delete chat. Please try again.");
      console.error("Failed to delete chat:", error);
    }
  };


  const handleRenameChat = async (chatId, newChatName) => {
    console.log("Renaming Chat:", chatId, newChatName);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/chatApi/change_chatName`,
        { chat_id: chatId, name: newChatName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const updatedChat = response.data.updatedChat;
      console.log("Updated Chat:", updatedChat);

      setUserData((prevUserData) =>
        prevUserData.map((chat) =>
          chat.chat_id === chatId ? { ...chat, name: updatedChat.name } : chat
        )
      );

      message.success("Chat renamed successfully!");
    } catch (error) {
      message.error("Failed to rename chat. Please try again.");
      console.error("Failed to rename chat:", error);
    }
  };

  const handleRenameDocument = async (documentId, newDocumentName) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/chatApi/change_documentName`,
        { chat_id: documentId, name: newDocumentName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      const updatedDocument = response.data.updatedChat;
      console.log("Updated Document:", updatedDocument);

      setUserData((prevUserData) =>
        prevUserData.map((chat) =>
          chat.chat_id === documentId ? { ...chat, name: updatedDocument.name } : chat
        )
      );

      message.success("Document renamed successfully!");
    } catch (error) {
      message.error("Failed to rename document. Please try again.");
      console.error("Failed to rename document:", error);
    }
  };

  return (
    <DashboardContext.Provider
      value={{
        sidebarData,
        setSidebarData,
        chatMessages,
        setChatMessages,
        userDocument,
        setUserDocument,
        UserData,
        setUserData,
        isCreatingChat,
        isSavingDocument,
        CurrentNewChatId,
        setCurrentNewChatId,
        handleDeleteChat,
        handleNewChatClick,
        handleSendMessage,
        handleSaveDocument,
        handleDeleteDocument,
        handleRenameChat,
        handleRenameDocument,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
