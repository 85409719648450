import React, { useState, useEffect } from "react";
import { Space, Table, Tag, Spin } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import PricingUpgrade from "../../../../src/components/pricing/pricingUpgarde";
import axios from "axios";

const Bill = () => {
  const { Column } = Table;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token'); 

      if (!token) {
        console.error('No token found in localStorage');
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/payment/getplan`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request headers
          },
        });

        const fetchedData = response.data.data;
        setData(fetchedData); 

        if (fetchedData.length > 0) {
          const latestPlan = fetchedData.reduce((latest, current) => {
            return new Date(current.subscriptionDate) > new Date(latest.subscriptionDate) ? current : latest;
          });
          setCurrentPlan(latestPlan);
        }

      } catch (error) {
        console.error("Failed to fetch payment plans:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (loading) {
    return (
      <div className="loading-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Spin size="large" tip="Loading your plans..." />
      </div>
    );
  }

  return (
    <div className="billContainer">
      <div className="d-flex items-center justify-content-between">
      <p className="fw-semibold">Current Plan</p>

      <p className="fw-medium absolute">
                <span onClick={() => setIsModalOpen(true)} className="text-primary cursor-pointer">
                  Upgrade Now.
          </span>
      </p>
      </div>
      {currentPlan ? (
        <div className="billStatment d-flex gap-2 mt-2">
          <p className="dark-bluish fw-normal">{currentPlan.plan.charAt(0).toUpperCase() + currentPlan.plan.slice(1)} Plan</p>
          <p className={`text-small ${currentPlan.status === 'active' ? 'parrot-green' : 'text-danger'} fw-medium`}>
            {currentPlan.status.charAt(0).toUpperCase() + currentPlan.status.slice(1)}
          </p>
        </div>
      ) : (
        <p>No current plan available</p>
      )}
    

      <div>
        <div className="tableSection pt-4">
          <div className="tableWrapper">
            <Table
              dataSource={data}
              rowKey="_id"
              pagination={{
                pageSize: 6,
                showSizeChanger: false,
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return <LeftOutlined />;
                  }
                  if (type === "next") {
                    return <RightOutlined />;
                  }
                  if (page > 5) {
                    return <span>...</span>;
                  }
                  return originalElement;
                },
                position: ["bottomCenter"],
              }}
            >
              <Column title="Date" dataIndex="subscriptionDate" key="date" render={date => new Date(date).toLocaleDateString()} />
              <Column title="Plan" dataIndex="plan" key="plan" 
              
              render={(Plan) => (
                <span
                 
                >
                 { Plan ? Plan.charAt(0).toUpperCase() + Plan.slice(1) : "Unknown"}
                </span>
              )}
              
              />
              <Column title="Amount" dataIndex="subscriptionAmount" key="amount" render={subscriptionAmount => `$${subscriptionAmount.toFixed(2)}`} />
              <Column
                title="Status"
                dataIndex="status"
                key="status"
                render={(status) => (
                  <span
                    style={{
                      backgroundColor:
                        status === "active"
                          ? "rgba(25, 212, 66, 0.18)"
                          : "rgba(181, 14, 14, 0.18)",
                      borderRadius: "27px",
                      fontWeight: "500",
                      padding: "5px 10px",
                    }}
                  >
                    {status ? status.charAt(0).toUpperCase() + status.slice(1) : "Unknown"}
                  </span>
                )}
              />
              <Column
                title="Subscription"
                dataIndex="billingCycle"
                key="subscription"
                render={(billingCycle) => billingCycle ? billingCycle.charAt(0).toUpperCase() + billingCycle.slice(1) : "N/A"}
              />
            </Table>
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={837}
      // className="upgrade-plans"
      >
        <PricingUpgrade />
      </Modal>
    </div>
  );
};

export default Bill;
