
import React, { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import threedots from "../assets/icons/3dots.svg";
import DelCardModal from "../dashboard/modals/delCardModal";
import RenameChatModal from "../dashboard/modals/RenameChatModal";
import '../styles/components/threedots.scss'

const ThreeDots = ({
  chatId,
  documentId,
  handleDeleteChat,
  handleDeleteDocument,
  savedDocumentUrl,
  handleRenameChat,
  handleRenameDocument,
}) => {
  const dropRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [actionType, setActionType] = useState("chat");



  const handleClickOutside = (event) => {
    if (dropRef.current && !dropRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handletoggle = (e) => {
    e.stopPropagation(); // Prevent sidebar close on click
    setShowPopup(!showPopup);
  };

  const showDelModal = (e) => {
    e.stopPropagation(); // Prevent sidebar close on click
    setIsDelModalOpen(true);
  };

  const downloadFile = async (s3Url) => {
    if (!s3Url) {
      alert("No document URL provided.");
      return;
    }
  
    setIsDownloading(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/chatApi/download_docs`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ s3Url }), // Use the provided s3Url
      });
  
      if (!response.ok) {
        throw new Error("Failed to download file");
      }
  
      // ✅ Get the filename from the response headers
      const contentDisposition = response.headers.get("Content-Disposition");
      let fileName = "downloaded-file"; // Default filename
  
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match.length > 1) {
          fileName = match[1]; // Extracted filename
        }
      } else {
        fileName = s3Url.split("/").pop(); // Fallback: Extract from URL
      }
  
      // ✅ Convert response to Blob
      const blob = await response.blob();
      const fileUrl = window.URL.createObjectURL(blob);
  
      // ✅ Create a download link and trigger it
      const a = document.createElement("a");
      a.href = fileUrl;
      a.download = fileName; // Use dynamic filename
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
      // ✅ Cleanup the object URL
      window.URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.error("Download error:", error);
      alert("Failed to download document");
    } finally {
      setIsDownloading(false);
    }
  };
  

  const showRenameModal = (e) => {
    e.stopPropagation();
    if (documentId) {
      setActionType("document");
    } else {
      setActionType("chat");
    }
    setIsRenameModalOpen(true);
    setIsDelModalOpen(false);
  };

  const handleConfirmDelete = () => {
    if (handleDeleteChat) {
      handleDeleteChat(chatId);
    } else {
      handleDeleteDocument(chatId);
    }
    setIsDelModalOpen(false);
  };

  return (
    <>
      <div className="rightArea " ref={dropRef}>
        <img
          src={threedots}
          alt="three dots"
          className="cursor-pointer"
          onClick={handletoggle}
        />
        {showPopup && (
          <div className="editDiv position-absolute   ">
            <div
              className="cursor-pointer v-center justify-content-left gap-2 mb-1"
              onClick={showRenameModal}
            >
              <Icon
                icon="la:pen"
                width="20"
                height="20"
                style={{ color: "black" }}
              />
              <span className="cursor-pointer text-small fw-medium">
                Rename
              </span>
            </div>
           {documentId && 
            <div
            className="cursor-pointer v-center justify-content-left gap-2 mb-1"
            onClick={() => downloadFile(savedDocumentUrl)} 
            >
            {isDownloading ? (
              <Icon icon="eos-icons:loading" width="20" height="20" style={{ color: "blue" }} />
            ) : (
              <Icon icon="la:download" width="20" height="20" style={{ color: "black" }} />
            )}
            <span className="cursor-pointer text-small fw-medium">
              {isDownloading ? "Downloading..." : "Download"}
            </span>
          </div>}
            <div
              className="cursor-pointer v-center justify-content-left gap-2"
              onClick={showDelModal}
            >
              <Icon
                icon="ph:trash"
                width="19"
                height="19"
                style={{ color: "red" }}
              />
              <span className="cursor-pointer text-danger text-small fw-medium gap-2">
                Delete
              </span>

              <span></span>

            </div>
          </div>
        )}
      </div>
      <DelCardModal
        isModalOpen={isDelModalOpen}
        setIsModalOpen={setIsDelModalOpen}
        onConfirm={handleConfirmDelete}
      />

      <RenameChatModal
        isModalOpen={isRenameModalOpen}
        setIsModalOpen={setIsRenameModalOpen}
        chatId={chatId}
        documentId={documentId}
        actionType={actionType} // Pass actionType to determine if it's chat or document
        handleRenameChat={handleRenameChat}
        handleRenameDocument={handleRenameDocument}


      />
    </>
  );
};

export default ThreeDots;
