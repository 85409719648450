import React, { useState ,useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import Buttoon from "../components/buttoon";
import check from "../assets/icons/checkIcon.svg";
import { useNavigate } from "react-router-dom";
import verifyUser from "../middleware/verifyUser";
import Swal from "sweetalert2";

const stripePromise = loadStripe(process.env.REACT_APP_PUBLIC_KEY);

const PricingCard = ({
  active,
  dollarYear,
  dollarMonth,
  planName,
  badge,
  year,
  month,
  features,
  priceId,
}) => {
  const navigate = useNavigate();
  const handleSubscribe = async () => {
    const isUser = await verifyUser();
  
    if (!isUser) {
      Swal.fire({
        icon: "error",
        title: "Create an Account",
        text: "Before purchasing the plan, please sign up or log in.",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/login");
      });
      return; 
    }
  
    try {
      const token = localStorage.getItem("token");
  
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/payment/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ priceId }),
        }
      );
  
      const data = await response.json();
  
      if (!response.ok) {
        const errorMessage = data.error || "An unexpected error occurred.";
        Swal.fire({
          icon: "error",
          title: "Checkout Error",
          text: errorMessage,
          confirmButtonText: "OK",
        });
        return;
      }
  
      if (data.sessionId) {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });
  
        if (error) {
          console.error("Stripe Checkout Error:", error.message);
          Swal.fire({
            icon: "error",
            title: "Payment Error",
            text: `There was an error redirecting to checkout: ${error.message}`,
            confirmButtonText: "OK",
          });
        }
      } else {
        console.error("Failed to get Stripe Checkout Session ID");
        Swal.fire({
          icon: "error",
          title: "Checkout Session Error",
          text: "Could not retrieve a valid checkout session ID. Please try again.",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error during checkout:", error);
      Swal.fire({
        icon: "error",
        title: "Unexpected Error",
        text: `An error occurred: ${error.message}. Please try again later.`,
        confirmButtonText: "OK",
      });
    }
  };


  const [userPlan, setUserPlan] = useState(null);

  useEffect(() => {
    const fetchUserPlan = async () => {
      const token = localStorage.getItem("token");
      if (!token) return;
  
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/payment/getplan`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const result = await response.json();
  
        if (response.ok && result?.data?.length > 0) {
          const activePlan = result.data.find(p => p.status === 'active');
          if (activePlan) {
            setUserPlan(activePlan.plan.toLowerCase()); // example: "basic"
          }
        }
      } catch (error) {
        console.error("Error fetching user plan:", error);
      }
    };
  
    fetchUserPlan();
  }, []);  
  // const handleSubscribe = async () => {
  //   const isUser = await verifyUser();

  //   if (!isUser) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Create an Account",
  //       text: "Before purchasing the plan, please sign up or log in.",
  //       confirmButtonText: "OK",
  //     }).then(() => {
  //       navigate("/login");
  //     });
  //   } else {
  //     try {
  //       const token = localStorage.getItem("token");

  //       const response = await fetch(
  //         `${process.env.REACT_APP_BASE_URL}/payment/create-checkout-session`,
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({ priceId }),
  //         }
  //       );

  //       const data = await response.json();

  //       if (data.sessionId) {
  //         const stripe = await stripePromise;
  //         const { error } = await stripe.redirectToCheckout({
  //           sessionId: data.sessionId,
  //         });

  //         if (error) {
  //           console.error("Stripe Checkout Error:", error.message);
  //         }
  //       } else {
  //         console.error("Failed to get Stripe Checkout Session ID");
  //       }
  //     } catch (error) {
  //       console.error("Error during checkout:", error);
  //     }
  //   }
  // };

  return (
    <div className="pricingContainer">
      <div className="header">
        <div className="cardHeading">
          <h1>{active === "Monthly" ? `$${dollarMonth}` : `$${dollarYear}`}</h1>
          <p>{active === "Monthly" ? month : year}</p>
        </div>
        <button style={{ cursor: "default" }} className="badge">
          {badge}
        </button>
      </div>
      <hr />
      <div className="cardBody">
        <ul>
          {features && features.length > 0 ? (
            features.map((feature, index) => (
              <li key={index} className="listItems d-flex gap-3 v-center">
                <img src={check} alt="Check Icon" className="checkImage" />
                {feature}
              </li>
            ))
          ) : (
            <li className="listItems">No features specified</li>
          )}
        </ul>
        {/* <Buttoon
          className="btn subscribeBtn"
          text="Subscribe"
          onClick={handleSubscribe}
        /> */}
                <Buttoon
                  className="btn subscribeBtn"
                  text={userPlan === planName ? "Subscribed" : "Subscribe"}
                  disabled={userPlan === planName}
                  onClick={userPlan === planName ? null : handleSubscribe}
                />


      </div>
    </div>
  );
};

export default PricingCard;
