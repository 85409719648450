// import React, { useState } from "react";
// import { Modal, Button, Radio, Input } from "antd";  // Import Radio instead of Checkbox
// import Swal from "sweetalert2";
// import axios from "axios";

// const FeedbackModal = ({ visible, onClose, onSubmit }) => {
//   const [selectedOption, setSelectedOption] = useState(""); // Track single selection
//   const [feedback, setFeedback] = useState("");
//   const [isSubmitting, setIsSubmitting] = useState(false); // Tracks submission state
//   const [error, setError] = useState("");

//   const options = [
//     "Lacks Detail",
//     "Inaccurate",
//     "Bad Writing",
//     "Too Short",
//     "Too Long",
//     "Other",
//   ];

//   const handleRadioChange = (e) => {
//     setSelectedOption(e.target.value); // Update state with the selected option
//   };

//   const handleSubmit = async () => {
//     if (!selectedOption && !feedback.trim()) {
//       setError("Please select an option or provide feedback.");
//       return; // Prevent submission if neither is filled
//     }

//     setError(""); // Clear previous errors
//     setIsSubmitting(true); // Disable button and indicate loading state

//     try {
//       const token = localStorage.getItem("token");
//       const response = await axios.post(
//         "http://localhost:4000/CreatefeedbackDown",
//         { feedback, selectedOption },
//         {
//           headers: { Authorization: `Bearer ${token}` },
//         }
//       );

//       Swal.fire({
//         title: "Feedback Submitted",
//         text: "Your feedback has been submitted successfully.",
//         icon: "success",
//       });

//       // Reset form after submission
//       setFeedback("");
//       setSelectedOption("");
//       onClose(); // Close the modal after submission
//     } catch (error) {
//       const errorMessage =
//         error.response?.data?.message ||
//         "An error occurred while submitting your feedback.";
//       Swal.fire({
//         title: "Error",
//         text: errorMessage,
//         icon: "error",
//       });
//     } finally {
//       setIsSubmitting(false); // Re-enable button after submission
//     }
//   };

//   return (
//     <Modal
//       title="What could we improve?"
//       open={visible}
//       onCancel={onClose}
//       footer={null}
//       className="custom-feedback-modal"
//     >
//       <div className="feedback-content">
//         {error && (
//           <div style={{ color: "red", marginBottom: "10px" }}>
//             <strong>{error}</strong>
//           </div>
//         )}

//         <p className="modal-subtitle">Select one option:</p>
//         <Radio.Group
//           options={options.map((option) => ({ label: option, value: option }))}
//           onChange={handleRadioChange}
//           value={selectedOption}
//           className="radio-group"
//         />

//         <Input.TextArea
//           placeholder="Additional details about what we could improve..."
//           rows={3}
//           className="feedback-textarea"
//           value={feedback}
//           onChange={(e) => setFeedback(e.target.value)}
//         />

//         <div className="submit-btn-container">
//           <Button
//             type="primary"
//             className="submit-btn mt-10"
//             onClick={handleSubmit}
//             disabled={isSubmitting || (!selectedOption && feedback.trim() === "")}
//           >
//             {isSubmitting ? "Submitting..." : "Submit Feedback"}
//           </Button>
//         </div>
//       </div>
//     </Modal>
//   );
// };

// export default FeedbackModal;
import React, { useState } from "react";
import { Modal, Button, Radio, Input } from "antd";  // Import Radio instead of Checkbox
import Swal from "sweetalert2";
import axios from "axios";

const FeedbackModal = ({ visible, onClose, onSubmit }) => {
  const [selectedOption, setSelectedOption] = useState(""); // Track single selection
  const [feedback, setFeedback] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Tracks submission state
  const [error, setError] = useState(""); // To track error messages

  const options = [
    "Lacks Detail",
    "Inaccurate",
    "Bad Writing",
    "Too Short",
    "Too Long",
    "Other",
  ];

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value); // Update state with the selected option
  };

  const handleSubmit = async () => {
    if (!feedback.trim()) {
      setError("Please enter your feedback.");
      return; // Prevent submission if feedback is empty
    }

    setError(""); // Clear previous errors
    setIsSubmitting(true); // Disable button and indicate loading state

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/CreatefeedbackDown`,
        { feedback, selectedOption },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        title: "Feedback Submitted",
        text: "Your feedback has been submitted successfully.",
        icon: "success",
      });

      // Reset form after submission
      setFeedback("");
      setSelectedOption("");
      onClose(); // Close the modal after submission
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "An error occurred while submitting your feedback.";
      Swal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
      });
    } finally {
      setIsSubmitting(false); // Re-enable button after submission
    }
  };

  return (
    <Modal
      title="What could we improve?"
      open={visible}
      onCancel={onClose}
      footer={null}
      className="custom-feedback-modal"
    >
      <div className="feedback-content">
        {error && (
          <div style={{ color: "red", marginBottom: "10px" }}>
            <strong>{error}</strong> {/* Display error message in red */}
          </div>
        )}

        <p className="modal-subtitle">Select one option:</p>
        <Radio.Group
          onChange={handleRadioChange}
          value={selectedOption}
          className="radio-group"
        >
          {options.map((option, index) => (
            <Radio key={index} value={option} className="feedback-radio">
              {option}
            </Radio>
          ))}
        </Radio.Group>

        <Input.TextArea
          placeholder="Additional details about what we could improve..."
          rows={3}
          className="feedback-textarea"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />

        <div className="submit-btn-container">
          <Button
            type="primary"
            className="submit-btn mt-10"
            onClick={handleSubmit}
            disabled={isSubmitting || (!feedback.trim())}  // Disable button if feedback is empty
          >
            {isSubmitting ? "Submitting..." : "Submit Feedback"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedbackModal;
