import React, { useState } from "react";
import Buttoon from "../components/buttoon";
import userIcon from "../assets/icons/userIcon.svg";
import pageIcon from "../assets/icons/mm.svg";
import { Icon } from "@iconify/react";
import DashboardChatArea from "../components/messagebox";
const DashboardChat = () => {
  const [clickedIcons, setClickedIcons] = useState({
    like: false,
    heart: false,
    dislike: false,
  });

  const handleClick = (iconName) => {
    setClickedIcons({
      like: false,
      heart: false,
      dislike: false,
      [iconName]: true,
    });
  };

  return (
    <div className="chat">
      <div className="kycWriting d-flex justify-content-between align-items-center">
      <h3 style={{ width: "90%" }}>ABC Risks</h3>
        <Buttoon
          className="btn btn-primary"
          minWidth={138}
          minHeight={39}
          text="Save Document"
        ></Buttoon>
      </div>
      <hr />
      <div className="chat-data d-flex flex-column ">
        <div className=" chattname">
          <img className="userIcon" src={userIcon} alt="UserIcon" />
          <div className="chatting">
            <h4>You</h4>
            <p>
              Customized policy that aligns with the latest AML regulations.
            </p>
          </div>
        </div>
        <div className=" chattname">
          <img className="pageIcon" src={pageIcon} alt="PageIcon" />
          <div className="chatting">
            <h4>ChatKYC</h4>
            <p>
              Great, Ali! Crafting a compliance feature for generating custom
              AML (Anti-Money Laundering) policies sounds like an awesome idea.
              Let's dive in and outline this PRD. Here's a first pass; please
              fill in any details or adjust as necessary.
            </p>
            <p>Compliance Policy Generator PRD</p>
            <p>
              Octaloop Tech needs to develop a feature for automatically
              generating customized AML compliance policies for its users. This
              will ensure alignment with the latest regulations and increase
              user trust.
            </p>
            <div className="d-flex gap-2 mt-4">
              <span
                className={`icon-container ${
                  clickedIcons.like
                    ? "clicked cursor-pointer"
                    : "unclicked cursor-pointer"
                }`}
                onClick={() => handleClick("like")}
              >
                <Icon icon="iconamoon:like" width="20" height="20" />
              </span>

              <span
                className={`icon-container ${
                  clickedIcons.heart
                    ? "clicked cursor-pointer"
                    : "unclicked cursor-pointer"
                }`}
                onClick={() => handleClick("heart")}
              >
                <Icon icon="mdi:heart-outline" width="20" height="20" />
              </span>

              <span
                className={`icon-container ${
                  clickedIcons.dislike
                    ? "clicked cursor-pointer"
                    : "unclicked cursor-pointer"
                }`}
                onClick={() => handleClick("dislike")}
              >
                <Icon icon="iconamoon:dislike" width="20" height="20" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <DashboardChatArea /> 
    </div>
  );
};

export default DashboardChat;
