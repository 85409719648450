import React, { useState, useEffect } from "react";
import axios from "axios";
import colordContract from "../assets/icons/coloredContarct.svg";
import questionMark from "../assets/icons/question-mark.svg";
import uploadd from "../assets/icons/upload.svg";
import folder from "../assets/icons/folder.svg";
import { Modal, Upload, message } from "antd"; // Add message to the import statement
import uploadIcon from "../assets/icons/uploadCloud.svg";
import { Icon } from "@iconify/react";
import Link from "antd/es/typography/Link";
import DashboardChatArea from "../components/messagebox";
import Buttoon from "../components/buttoon";

const DashboardMain = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const [loadingTemplates, setLoadingTemplates] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [handlePromat, setHandlePromat] = useState(null); // Store selected prompt

  const { Dragger } = Upload;
  const props = {
    name: "file",
    multiple: true,
    action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleTemplateClick = async () => {
    setLoadingTemplates(true); //
    try {
      const response = await axios.get(
        "https://fcp-staging.chatkyc.ai/browse_policy_templates"
      );
      setTemplates(response.data);
      console.log(response.data);
    } catch (error) {
      console.error("Error fetching compliance templates:", error);
      message.error("Failed to load compliance templates.");
    } finally {
      setLoadingTemplates(false); // Set loading state to false after request completes or fails
    }
  };

  const handlePrometClick = (text) => {
    setHandlePromat(text);
  };

  return (
    <>
      <div className="dashboardMainContainer d-flex justify-content-between position-relative">
        {showContent && (
          <div className="welcomekycDiv flex-column gap-2 h-center v-center">
            <h2 className="fw-bold">Welcome to ChatKYC</h2>

            <div className="customAMLDiv w-100 mt-5">
              <div className="card1  v-center h-center gap-2 ">
                <img src={colordContract} alt="" />
                <p className="fw-medium text-large text-center" onClick={() => handlePrometClick('Help me improve a policy')}>
                  Help me improve a policy
                </p>
                <Icon
                  icon="ic:round-keyboard-arrow-right"
                  width="28"
                  height="28"
                  style={{ color: "black" }}
                />
              </div>
              <div className="card1 v-center h-center gap-2 ">
                <img src={questionMark} alt="" />
                <p className="fw-medium text-large text-center" onClick={() => handlePrometClick('Summarize a regulatory text')}>
                  Summarize a regulatory text
                </p>
                <Icon
                  icon="ic:round-keyboard-arrow-right"
                  width="28"
                  height="28"
                  style={{ color: "black" }}
                />
              </div>

              <div className="card1 v-center h-center gap-2 ">
                <img src={uploadd} alt="" />
                  <p className="fw-medium text-large text-center" onClick={() => handlePrometClick('Upload and review your compliance document.')}>
                    Upload and review your compliance document.
                  </p>
                <Icon
                  icon="ic:round-keyboard-arrow-right"
                  width="40"
                  height="40"
                  style={{ color: "black" }}
                />
              </div>

              <div className="card1 v-center h-center gap-2 cursor-pointer">
                <img src={folder} alt="" />
                <p className="fw-medium text-large text-center" onClick={handleTemplateClick}>
                  Browse and use compliance templates.
                </p>
                <Icon
                  icon="ic:round-keyboard-arrow-right"
                  width="40"
                  height="40"
                  style={{ color: "black" }}
                />
              </div>
            </div>
          </div>
        )}
        <DashboardChatArea setShowContent={setShowContent} templates={templates} handlePromat={handlePromat} />
      </div>

      <Modal
        title={<h4>Upload File</h4>}
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        centered
        width={666}
        height={318}
        className="file-upload-modal"
      >
        <div className="uploadboxwrap">
          <Dragger {...props}>
            <div className=" d-flex flex-column justify-content-center align-items-center">
              <img className="w-100" src={uploadIcon} alt="Upload Icon" />
              <h5>Select file to upload</h5>
              <p>Or drag & drop here</p>
            </div>
          </Dragger>
        </div>
      </Modal>
    </>
  );
};

export default DashboardMain;
