import React, { useState, useEffect, useRef } from "react";
import "./pages/home/DashboardChatArea.css";
import { useDashboardContext } from "./ContextData";
import { useNavigate, useParams } from "react-router-dom";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { Modal } from "antd";
import { Icon } from "@iconify/react";
import Buttoon from "../components/buttoon";
import verifyUser from "../middleware/verifyUser";
import chatKycLogo from "../assets/icons/kycLogo.svg";
import PricingUpgrade from "./pricing/pricingUpgarde";
import promptClip from "../assets/icons/promptClip.svg";
import paperPlane from "../assets/icons/paperPlain.svg";
import chatLoggo from "../assets/icons/dashLogo.png";
import ChatRating from "./ChatRating";

const DashboardChatArea = ({ setShowContent, templates, handlePromat }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [selectedTemplate, setselectedTemplate] = useState();
  const [template, setTemplate] = useState([]);
  const [showTemplateList, setShowTemplateList] = useState(true);
  const [isUser, setIsUser] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const isTabActiveRef = useRef(true);
  const typingEffectIntervalRef = useRef(null);
  const [hasChatHistory, setHasChatHistory] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [ChatName, setChatName] = useState('New Chat');
  const previousUserId = useRef(null);

  const { chatMessages,
    handleSendMessage,
    handleSaveDocument,
    setUserData,
    isCreatingChat,
    isSavingDocument,
    UserData
  } = useDashboardContext();

  const [chatMessage, setChatMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const chatEndRef = useRef(null);
  const handlePromatRef = useRef(handlePromat);
  const [messageContent, setMessageContent] = useState("");


  const { id: chatId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    handlePromatRef.current = handlePromat;
  }, [handlePromat]);


  const loadChatHistoryFromLocalStorage = (chatId) => {
    const savedHistory = localStorage.getItem(`chatHistory-${chatId}`);
    return savedHistory ? JSON.parse(savedHistory) : [];
  };

  function adjustTextareaHeight(e) {
    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
    if (e.target.scrollHeight > 280) {
      e.target.style.height = "280px";
      e.target.style.overflowY = "auto";
    } else {
      e.target.style.overflowY = "hidden";
    }
  }


  useEffect(() => {
    setselectedTemplate(null);
    const savedHistory = loadChatHistoryFromLocalStorage(chatId);
    if (savedHistory.length > 0) {
      setChatHistory(savedHistory);
      setHasChatHistory(true);
      setShowContent(false);
    } else {
      fetchChatHistory(chatId);
    }

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [chatId]);

  useEffect(() => {
    const loginUserId = localStorage.getItem("userId");
    setUserId(loginUserId);

    const checkUser = async () => {
      const result = await verifyUser();
      if (!result) {
        navigate("/login");
      } else {
        setIsUser(true);
      }
    };
    checkUser();
  }, [chatId, navigate]);



  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);



  const fetchChatHistory = async (chatId) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/chatApi/chat/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const fetchedHistory = response.data.chats;

      const formattedHistory = fetchedHistory.flatMap((chat) =>
        chat.conversations.map((conversation) => ({
          user: conversation.user || "",
          bot: conversation.bot || "",
          timestamp: conversation.timestamp || "",
        }))
      );

      setChatHistory(formattedHistory);
      setShowContent(formattedHistory.length === 0);
    } catch (error) {
      console.error("Error fetching chat history:", error);
      message.error("Failed to fetch chat history.");
    }
  };




  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 0) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...files]);
      message.success(`${files.length} file(s) selected.`);
    }
  };

  
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        isTabActiveRef.current = false;
      } else {
        isTabActiveRef.current = true;
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const cancelFileUpload = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const userInitials = localStorage.getItem("name")
    ?.split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();



  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [chatHistory]);

  useEffect(() => {
    if (handlePromat) {
      sendMessage(handlePromat, true);

    }
  }, [handlePromat]);

  const resetFileInput = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.value = "";
    }
    setLoadingIndex(null);
  };

  // const sendMessage = async (messageContent, isPrompt = false) => {
  //   if (typeof messageContent !== 'string') {
  //     console.error("messageContent is not a string:", messageContent);
  //     messageContent = ""; // Default to empty string
  //   }
  
  //   if (!messageContent.trim() && selectedFiles.length === 0) {
  //     return;
  //   }
  
  //   setIsLoading(true);
  
  //   const newUserMessage = {
  //     user: messageContent,
  //     bot: "",
  //     loading: true,
  //   };
  
  //   setChatHistory((prev) => [...prev, newUserMessage]);
  //   const userMessageIndex = chatHistory.length;
  //   setLoadingIndex(userMessageIndex);
  
  //   const messagePayload = {
  //     messageContent,
  //     files: selectedFiles,
  //     chatId,
  //     userId,
  //   };
  
  //   try {
  //     // Optional: Check for document permissions (if any)
  //     if (selectedFiles.length > 0) {
  //       const documentCheckResponse = await axios.post(
  //         `${process.env.REACT_APP_BASE_URL}/payment/getfreedocument`,
  //         { userId, chatId },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           },
  //         }
  //       );
  
  //       if (documentCheckResponse.status === 403) {
  //         throw new Error("Access Denied: Upgrade Required");
  //       }
  //     }
  
  //     setShowContent(false); // Hide content if necessary
  
  //     // Send the message and get the response from the backend
  //     const response = await handleSendMessage(messagePayload);
  //     const botResponse = response.botResponse;
  //     let responseIndex = 0;
  //     const typingSpeed = 5; // Delay in ms between each character
  
  //     const typingInterval = setInterval(() => {
  //       if (!isTabActiveRef.current) {
  //         setChatHistory((prev) => {
  //           const updated = [...prev];
  //           updated[userMessageIndex].bot = botResponse;
  //           return updated;
  //         });
  //         clearInterval(typingInterval);
  //         setLoadingIndex(null);
  //       } else {
  //         setChatHistory((prev) => {
  //           const updated = [...prev];
  //           updated[userMessageIndex].bot = botResponse.substring(0, responseIndex);
  //           return updated;
  //         });
  //         responseIndex++;
      
  //         if (responseIndex > botResponse.length) {
  //           clearInterval(typingInterval);
  //           setLoadingIndex(null);
  //         }
  //       }
  //     }, typingSpeed);
      
  
  //   } catch (error) {
  //     // Handle errors in the message processing
  //     if (error.response?.status === 403 || error.message === "Access Denied: Upgrade Required") {
  //       setIsModalOpen(true);
  //       message.warning("You need to upgrade your plan to proceed.");
  //     } else {
  //       message.error("Failed to process the request. Please try again.");
  //       setChatHistory((prev) => {
  //         const updated = [...prev];
  //         updated[userMessageIndex].bot = "Error: Unable to process the message.";
  //         return updated;
  //       });
  //     }
  
  //     setLoadingIndex(null);
  //   } finally {
  //     setIsLoading(false);
  //   }
  
  //   // Clear message input and selected files
  //   setChatMessage("");
  //   setSelectedFiles([]);
  // };
  
  
  const sendMessage = async (messageContent, isPrompt = false) => {
    if (typeof messageContent !== 'string') {
      console.error("messageContent is not a string:", messageContent);
      messageContent = ""; // Default to empty string
    }

    if (!messageContent.trim() && selectedFiles.length === 0) {
      return;
    }
    setIsLoading(true);

    const newUserMessage = {
      user: messageContent,
      bot: "",
      loading: true,
    };

    setChatHistory((prev) => [...prev, newUserMessage]);
    const userMessageIndex = chatHistory.length;
    setLoadingIndex(userMessageIndex);

    const messagePayload = {
      messageContent,
      files: selectedFiles,
      chatId,
      userId,
    };

    try {
      if (selectedFiles.length > 0) {
        const documentCheckResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/payment/getfreedocument`,
          { userId, chatId },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (documentCheckResponse.status === 403) {
          throw new Error("Access Denied: Upgrade Required");
        }
      }
      setShowContent(false);

      const response = await handleSendMessage(messagePayload);
      const botResponse = response.botResponse;
      let responseIndex = 0;
      const typingSpeed = 5;

      const typingInterval = setInterval(() => {
        if (!isTabActiveRef.current) {
          setChatHistory((prev) => {
            const updated = [...prev];
            updated[userMessageIndex].bot = botResponse;
            return updated;
          });
          clearInterval(typingInterval);
          setLoadingIndex(null);
        } else {
          setChatHistory((prev) => {
            const updated = [...prev];
            updated[userMessageIndex].bot = botResponse.substring(
              0,
              responseIndex
            );
            return updated;
          });
          responseIndex++;

          if (responseIndex > botResponse.length) {
            clearInterval(typingInterval);
            setLoadingIndex(null);
          }
        }
      }, typingSpeed);

    } catch (error) {
      if (error.response?.status === 403 || error.message === "Access Denied: Upgrade Required") {
        setIsModalOpen(true);
        message.warning("You need to upgrade your plan to proceed.");
      } else {
        message.error("Failed to process the request. Please try again.");
        setChatHistory((prev) => {
          const updated = [...prev];
          updated[userMessageIndex].bot = "Error: Unable to process the message.";
          return updated;
        });
      }

      setLoadingIndex(null);
    }
    finally {
      setIsLoading(false);
    }


    setChatMessage("");
    setSelectedFiles([]);


  };

  const handleSave = async () => {
    setIsConfirmationModalOpen(false);

    const saveQuery = { user: "Save the document", bot: "Processing..." };
    setChatHistory((prevHistory) => [...prevHistory, saveQuery]);

    try {
      const userId = localStorage.getItem("userId");
      if (!chatId || !userId) {
        throw new Error("Missing chatId or userId.");
      }

      const response = await handleSaveDocument(chatId, userId);
      const botResponse = response.botResponse;

      if (botResponse) {
        setChatHistory((prevHistory) => {
          const updatedHistory = [...prevHistory];
          updatedHistory[updatedHistory.length - 1] = {
            user: "Save the document",
            bot: `<span style="color:green;"><strong>✔️ Document Saved.</strong></span> <a href="${process.env.REACT_APP_BASE_URL_PHYTHON}/uploads/${botResponse}" download>Download Document</a>`,
          };
          return updatedHistory;
        });
        message.success("Document saved successfully!");
      } else {
        throw new Error("Failed to retrieve botResponse.");
      }
    } catch (error) {
      setChatHistory((prevHistory) => {
        const updatedHistory = [...prevHistory];
        updatedHistory[updatedHistory.length - 1] = {
          user: "Save the document",
          bot: "Error: Failed to save document. Please try again.",
        };
        return updatedHistory;
      });
      console.error("Error in handleSave:", error.message);
      message.error("Failed to save document.");
    }
  };

  const parseMarkdown = (text) => {
    if (typeof text !== "string") return "";

text = text.replace(/([^\<\>]+(?:\n|$))/g, (match) => {
  return `<p style="line-height: 2; margin-bottom: 20px;">${match.trim()}</p>`;
});
  
    text = text.replace(/<p>(.*?)<\/p>/g, (match) => {
      return `<p style="line-height: 2; margin-bottom: 20px;">${match}</p>`;
    });
 

  if (!/<[a-z][\s\S]*>/i.test(text)) {
    text = `<p style="line-height: 2; margin-bottom: 20px; text-align: justify;">${text}</p>`;
  }

    if (!/<[a-z][\s\S]*>/i.test(text)) {
      text = `<div style="line-height:2; margin-bottom: 20px;  text-align: justify;">${text}</div>`;
    }

    text = text.replace(/<h[1-5]>.*?<\/h[1-5]>/g, (match) => {
      return `<div style="line-height: 2; margin-bottom: 20px;">${match}</div>`;
    });

    text = text.replace(/<p>(.*?)<\/p>/g, (match) => {
      return `<p style="line-height: 2; margin-bottom: 20px;">${match}</p>`;
    });

    // Convert Links
    text = text.replace(
      /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g,
      '<a href="$2" target="_blank" style="line-height: 2;color: blue; text-decoration: underline;">$1</a>'
    );

    // Convert Headers
    text = text.replace(/^# (.+)\s*$/gm, '<h2 style="color: black;"><strong>$1</strong></h2>');
    text = text.replace(/^## (.+)\s*$/gm, '<h3 style="color: black;"><strong>$1</strong></h3>');
    text = text.replace(/###(.+)\s*$/gm, '<h6 style="color: black;"><strong>$1</strong></h6>');
    text = text.replace(/#(.+)\s*$/gm, '<h6 style="color: black;"><strong>$1</strong></h6>');


    text = text.replace(/^### (.+)\s*$/gm, (match, content) => {
      return `<ul style="line-height:2;list-style-type: disc; margin-left: 20px;"><li style="font-weight: bold;">${content}</li></ul>`;
    });
    text = text.replace(/^#### (.+)\s*$/gm, '<h4 style="color: black;">$1</h4>');
    text = text.replace(/^##### (.+)\s*$/gm, '<h5 style="color: black;">$1</h5>');

    // Convert Bold and Italic Text
    text = text.replace(/\*\*(.*?)\*\*/g, '<strong">$1</strong>');
    text = text.replace(/\*(.*?)\*/g, "<em style='line-height: 2;'>$1</em>");

    // Convert Horizontal Lines (---)
    text = text.replace(/^---\s*$/gm, '<div style="line-height: 2;border-top: 1px solid #ccc; width: 100%; margin: 20px 0;"></div>');
    text = text.replace(/^--\s*$/gm, '<div style="line-height: 2;border-top: 1px solid #ccc; width: 100%; margin: 20px 0;"></div>');

    // Convert Lists
    // text = text.replace(/^\- (.+?)$/gm, "<ul style='line-height: 2; list-style-type: disc; margin-left: 20px;'><li style='font-weight: bold;'>$1</li></ul>");
    text = text.replace(/^\- (.+?)$/gm, "<ul style='line-height: 2;list-style-type: disc; margin-left: 20px; 'font-weight: bold;'><li>$1</li></ul>");
    text = text.replace(/^\d+\.\s(.+)$/gm, "<ol style='line-height: 2; margin-left: 20px; 'font-weight: bold;'><li style='font-weight: bold;>$1</li></ol>");

    

    // Convert Tables
    const tableRegex = /\|(.+)\|\n\|(:?-+:?\|)+\n((\|.+?\|\n)+)/g;
    text = text.replace(tableRegex, (match) => {
      const rows = match.trim().split("\n");
      const headers = rows[0].split("|").filter((cell) => cell.trim());
      const body = rows.slice(2).map((row) => row.split("|").filter((cell) => cell.trim()));

      let tableHtml = '<table style="border: 1px solid #ddd; border-collapse: collapse; width: 100%; margin: 20px 0;">';

      // Table Header
      tableHtml += "<thead><tr>";
      headers.forEach((header) => {
        tableHtml += `<th style="border: 1px solid #ddd; padding: 8px; text-align: left;">${header.trim()}</th>`;
      });
      tableHtml += "</tr></thead>";

      // Table Body
      tableHtml += "<tbody>";
      body.forEach((row) => {
        tableHtml += "<tr>";
        row.forEach((cell) => {
          tableHtml += `<td style="border: 1px solid #ddd; padding: 8px;">${cell.trim()}</td>`;
        });
        tableHtml += "</tr>";
      });
      tableHtml += "</tbody></table>";

      return tableHtml;
    });

    const flowchartRegex = /plaintext\n([\s\S]+?)\n/g;
    text = text.replace(flowchartRegex, (match, content) => {
      const lines = content.trim().split("\n");
      let html = '<div style="display: flex; flex-direction: column; align-items: center; position: relative; gap: 15px;">';

      lines.forEach((line) => {
        if (line.trim() === "|") {
          // Vertical Line
          html +=
            '<div style="width: 2px; height: 30px; background-color: black; margin: 0 auto;"></div>';
        } else if (line.trim() === "v") {
          // Downward Arrow
          html +=
            '<div style="width: 2px; height: 20px; background-color: black; position: relative; margin: 0 auto;">' +
            '<div style="width: 0; height: 0; border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 8px solid black; position: absolute; bottom: -5px; left: -5px;"></div>' +
            '</div>';
        } else if (line.includes("-->")) {
          // Indented Process/Details
          const [key, value] = line.split("-->");
          html +=
            `<div style="display: flex; align-items: center; gap: 10px; position: relative;">` +
            `<div style="width: 2px; height: 20px; background-color: black;"></div>` +
            `<div style="border: 1px solid #444; padding: 8px 12px; border-radius: 5px; background-color: #e8f4fc; font-size: 14px;">${value.trim()}</div>` +
            `</div>`;
        } else if (line.startsWith("[")) {
          // Heading Blocks
          html +=
            `<div style="border: 1px solid black; padding: 8px 12px; border-radius: 5px; background-color: #f0f0f0; font-weight: bold; text-align: center; width: fit-content;">${line.trim()}</div>`;
        } else {
          // Standard Block
          html +=
            `<div style="border: 1px solid black; padding: 8px 12px; border-radius: 5px; background-color: #e8f4fc; text-align: center; width: fit-content;">${line.trim()}</div>`;
        }
      });

      html += '</div>';
      return html;
    });

    text = text.replace(/\\n/g, "<br>");

    return text;

  };

  const handleTemplate = () => {
    if (templates && Array.isArray(templates.templates)) {
      setShowContent(false);
      setShowTemplateList(true)
      setTemplate(templates.templates);
    } else {
      console.error("templates is not an array:", templates);
    }
  };

  useEffect(() => {
    handleTemplate();

    if (previousUserId.current !== null && previousUserId.current !== chatId) {
      setShowTemplateList(false);
    } else if (previousUserId.current === null || previousUserId.current === chatId) {
      setShowTemplateList(true);
    }

    previousUserId.current = chatId;
  }, [chatId, templates]);

  // const handleTemplateSelect = async (template_name) => {
  //   try {
  //     setShowTemplateList(false);
  
  //     // Add user's template selection to the chat history with a loading state
  //     const userMessageIndex = chatHistory.length;
  //     setChatHistory((prev) => [
  //       ...prev,
  //       { user: template_name, bot: "", loading: true }, // User's message is the template name
  //     ]);
  //     setLoadingIndex(userMessageIndex);
  
  //     // Send the selected template to the backend API to get the response
  //     const response = await axios.post(
  //       "https://fcp-staging.chatkyc.ai/generate_policy",
  //       {
  //         user_id: userId,
  //         chat_id: chatId,
  //         template_name: template_name,
  //       }
  //     );
  
  //     setselectedTemplate(template_name);
  
  //     // Update the chat name in the UserData directly after template selection
  //     const botResponse = response.data.response;
  //     const updatedName = response.data.name;
  
  //     // Update chat name in both chat history and sidebar
  //     setUserData((prevUserData) =>
  //       prevUserData.map((chat) =>
  //         chat.chat_id === chatId ? { ...chat, name: updatedName } : chat
  //       )
  //     );
  
  //     let responseIndex = 0;
  //     const typingSpeed = 5;
  
  //     const typingInterval = setInterval(() => {
  //       if (!isTabActiveRef.current) {
  //         setChatHistory((prev) => {
  //           const updated = [...prev];
  //           updated[userMessageIndex].bot = botResponse;
  //           return updated;
  //         });
  //         clearInterval(typingInterval);
  //         setLoadingIndex(null);
  //       } else {
  //         setChatHistory((prev) => {
  //           const updated = [...prev];
  //           updated[userMessageIndex].bot = botResponse.substring(0, responseIndex);
  //           return updated;
  //         });
  //         responseIndex++;
  
  //         if (responseIndex > botResponse.length) {
  //           clearInterval(typingInterval);
  //           setLoadingIndex(null);
  //         }
  //       }
  //     }, typingSpeed);
  
  //   } catch (error) {
  //     console.error("Error generating policy:", error);
  //     message.error("Failed to generate policy.");
  //   }
  // };
  const handleTemplateSelect = async (template_name) => {
    try {
        setShowTemplateList(false); // Hide template list after selection

        // Add the template selection to chat history with a loading state
        const userMessageIndex = chatHistory.length;
        setChatHistory((prev) => [
            ...prev,
            { user: template_name, bot: "", loading: true }, // User's template name as message
        ]);
        setLoadingIndex(userMessageIndex);

        // Send the template to the backend to get the bot response
        const response = await axios.post(
            "https://fcp-staging.chatkyc.ai/generate_policy",
            {
                user_id: userId,
                chat_id: chatId,
                template_name: template_name,
            }
        );

        setselectedTemplate(template_name); // Update the selected template state

        // Process the bot response
        const botResponse = response.data.response;

        // Update the chat name in the UserData
        const updatedName = response.data.name;
        setUserData((prevUserData) =>
            prevUserData.map((chat) =>
                chat.chat_id === chatId ? { ...chat, name: updatedName } : chat
            )
        );

        // Typing effect logic to reveal the bot's response slowly
        let responseIndex = 0;
        const typingSpeed = 5; // Speed of typing effect

        const typingInterval = setInterval(() => {
            if (!isTabActiveRef.current) {
                // If the tab is not active, show the full response immediately
                setChatHistory((prev) => {
                    const updated = [...prev];
                    updated[userMessageIndex].bot = botResponse;
                    return updated;
                });
                clearInterval(typingInterval);
                setLoadingIndex(null);
            } else {
                // Gradually show the bot response character by character
                setChatHistory((prev) => {
                    const updated = [...prev];
                    updated[userMessageIndex].bot = botResponse.substring(0, responseIndex);
                    return updated;
                });
                responseIndex++;

                // If the typing is complete, show the full response
                if (responseIndex >= botResponse.length) {
                    setChatHistory((prev) => {
                        const updated = [...prev];
                        updated[userMessageIndex].bot = botResponse;
                        return updated;
                    });
                    clearInterval(typingInterval);
                    setLoadingIndex(null);
                }
            }
        }, typingSpeed); // Adjust typing speed here
    } catch (error) {
        console.error("Error generating policy:", error);
        message.error("Failed to generate policy.");
    }
};

  


  const getName = async () => {
    if (chatId) {
      try {
        const updatedChat = UserData?.find(
          (chat) => chat.chat_id === chatId
        );

        if (updatedChat && updatedChat.name) {
          setChatName(updatedChat.name);
        } else {
          setChatName('New Chat');
        }
      } catch (error) {
        console.error("Error fetching chat name:", error);
        setChatName('New Chat');
      }
    }
  }
  useEffect(() => {
    getName();
  }, [UserData])


  const showSaveDocumentButton = chatHistory.length > 0;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
    
{selectedTemplate && (
  <div className="kycWriting d-flex justify-content-between align-items-center border-bottom border-3">
    {!isMobile && (
      <h3>{selectedTemplate}</h3>
    )}
  </div>
)}

{chatHistory.length > 0 && (
  <div className="kycWriting d-flex justify-content-between align-items-center border-bottom border-3">
    {chatHistory.length > 0 && !isMobile && (
      <h3>{ChatName}</h3> 
    )}
    {showSaveDocumentButton && (
      <Buttoon
        onClick={isSavingDocument ? null : handleSave}
        className="btn btn-primary"
        text={isSavingDocument ? "Saving..." : "Save Document"}
        disabled={isSavingDocument}
      />
    )}
  </div>
)}



      {/* {chatHistory.length > 0 && (
        <div className="kycWriting d-flex justify-content-between align-items-center  border-bottom border-3">
          {chatHistory.length > 0 && !isMobile && <h3>{ChatName}</h3>}
          {showSaveDocumentButton && (
            <Buttoon
              onClick={isSavingDocument ? null : handleSave}
              className="btn btn-primary"
              text={isSavingDocument ? "Saving..." : "Save Document"}
              disabled={isSavingDocument}
            />
          )}
        </div>
      )} */}
      <div className="m-3">
        {template && Array.isArray(template) && showTemplateList && (
          <div>
            {template.map((value, index) => (
              <li key={index} style={{
                listStyleType: "none",
                paddingTop: "10px"
              }}>
                <a
                  onClick={() => handleTemplateSelect(value.name)}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "blue",
                    marginRight: "15px"
                  }}
                >
                  {value.name}
                </a>
              </li>
            ))}
          </div>
        )}
      </div>

      <div className="chatArea">
        <div className="chatWithBot">
          {chatHistory.map((chat, index) => (
            <div
              key={index}
              className={`chatMessageContainer ${chat.user ? "userMessage" : "botMessage"
                }`}
            >
              {chat.user && <div className="userInitials">{userInitials}</div>}
              <div className={`chatBubble ${chat.user ? "" : "botChatBubble"}`}>
                <p
                  style={{
                    margin: 0,
                    opacity: index === loadingIndex ? 0.5 : 1,
                  }}
                >
                  {chat.user ? (
                    <>
                      {chat.user}
                      {index === loadingIndex && (
                        <div
                          style={{
                            display: "inline-block",
                            marginLeft: "13px",
                          }}
                        >
                          <Spin indicator={antIcon} className="loader-spin" />
                        </div>
                      )}
                    </>
                  ) : (
                    chat.bot
                  )}
                </p>
              </div>
              {chat.bot && (
                <div className="botResponse">
                  <img
                    style={{ width: "50px", objectFit: "cover" }}
                    className="botMessageLogo"
                    src={chatLoggo}
                    alt="ChatKYC Logo"
                  />
                  <div className="botChatBubble">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: parseMarkdown(chat.bot),
                      }}
                    />
                    <div className="bg-pink">
                      <ChatRating onRate={(rating) => console.log("User rated:", rating)} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div ref={chatEndRef} />
        </div>

        <div className="promptDiv">
          {selectedFiles.length > 0 && (
            <div className="selectedFileName ">
              {selectedFiles.map((file, index) => (
                <div className="filesDesign d-flex gap-2 justify-content-center align-items-center">
                  <div className="attachment-files">
                    <p key={index}>{file.name}</p>
                  </div>

                  <Icon
                    icon="radix-icons:cross-1"
                    width="12"
                    height="12"
                    color="red"
                    onClick={() => cancelFileUpload(index)}
                  />
                </div>
              ))}
            </div>
          )}
          <div className="promptArea1">
            <label htmlFor="fileInput">
              <img className="cursor-pointer" src={promptClip} alt="Upload" />
            </label>
            <input
              id="fileInput"
              type="file"
              accept=".pdf,.csv,.pptx,.docx,.txt"
              onChange={handleFileChange}
              multiple
              style={{ display: "none" }}
            />
          </div>
          <div className="promptArea2">

            <textarea
              id="promptarea2"
              className="w-full p-2 rounded resize-none"
              rows="1"
              placeholder="Type your message..."
              value={chatMessage} // Ensure it is controlled
              onChange={(e) => setChatMessage(e.target.value)} // Update state
              onInput={(e) => adjustTextareaHeight(e)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  sendMessage(chatMessage, false);
                  setChatMessage("");
                }
              }}
              style={{ border: "none", maxHeight: "280px", overflowY: "auto" }}
            ></textarea>

          </div>
          {/* <div className="promptArea3">
            <img
              className="cursor-pointer"
              src={paperPlane}
              alt="Send"
              disabled={isLoading}
              onClick={sendMessage}
            />
          </div> */}

          <div className="promptArea3">
            <img
              className="cursor-pointer"
              src={paperPlane}
              alt="Send"
              disabled={isLoading}
              onClick={() => {
                sendMessage(chatMessage, false);
                setChatMessage(""); // Clear textarea after sending
              }}
            />
          </div>



        </div>
      </div>
      <Modal
        open={isConfirmationModalOpen}
        onOk={handleSave}
        onCancel={() => setIsConfirmationModalOpen(false)}
        footer={null}
        className="custom-confirmation-modal"
      >
        <div style={{ textAlign: "center", padding: "20px" }}>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            Do you want to save this document?
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <Buttoon
              className="btn btn-primary"
              text="Create Document"
              onClick={handleSave}
            />
            <Buttoon
              className="btn btn-secondary"
              text="Cancel"
              onClick={() => setIsConfirmationModalOpen(false)}
            />
          </div>
        </div>
      </Modal>
      <Modal
        open={isModalOpen}
        onOk={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={837}
      >
        <PricingUpgrade />
      </Modal>
      {apiResponse && (
        <div className="apiResponse">
          <p>{apiResponse}</p>
        </div>
      )}
    </>
  );
};

export default DashboardChatArea;
